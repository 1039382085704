import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';
import { AlertBanner, FooterBanner } from 'components';
import { FORMAT_GRAPH_DATA } from 'utilities';
import { Layout } from 'containers';
import {
  HomeSearchBanner,
  BestInNZ,
  Awards,
  TourCategories,
  TourList,
  ActivityList,
  InstagramList,
  ProudSupporters,
  Review,
} from 'layouts';
import Lazyload from 'react-lazyload';
import { CSSTransition } from 'react-transition-group';
import { BASE_URL, FOOTER_BANNER_ATTRIBUTES } from 'config';

const organisationSchema = [
  {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    url: 'https://hakatours.com/',
    name: 'Haka Tours',
    legalName: 'Haka Tourism Group',
    logo: 'https://hakatourismgroup.com/wp-content/uploads/2019/07/haka-tours.png',
    description: `Founded by Ryan Sanders in 2007. Haka Tours create small group guided tours within the adventure and snow sectors.
    The Haka Tourism Group is made up of nine niche tourism businesses, growing at an average of 80% year on year.
    The company won the 2019 ‘Adventure Tourism Awards’ inaugural ‘Cultural Tourism’ award for New Zealand.
    The company was also a Deloitte Fast 50 Winner as New Zealand’s 21st fastest-growing business and the number one fastest-growing tourism business in 2019.`,
    foundingDate: '2007',
    founders: [
      {
        '@type': 'Person',
        name: 'Ryan Sanders',
      },
    ],
    address: {
      '@type': 'PostalAddress',
      streetAddress: '27 Gillies Avenue',
      addressLocality: 'Newmarket',
      addressRegion: 'Auckland',
      postalCode: '1023',
      addressCountry: 'NZ',
    },
    contactPoint: {
      '@type': 'ContactPoint',
      contactType: 'customer support',
      telephone: '[+64 9 520 5593]',
      email: 'info@hakatours.com',
    },
    sameAs: [
      'https://www.facebook.com/HakaTours',
      'https://www.instagram.com/hakatours/',
      'https://twitter.com/hakatours',
      'https://www.youtube.com/user/HakaTours',
    ],
  },
];

const navigationSchema = [
  {
    '@context': 'http://schema.org',
    '@type': 'ItemList',
    itemListElement: [
      {
        '@type': 'SiteNavigationElement',
        position: 1,
        name: 'New Zealand Tours',
        description: 'Join Haka Tours for the Adventure of a Lifetime',
        url: 'https://hakatours.com/new-zealand-tours/',
      },
      {
        '@type': 'SiteNavigationElement',
        position: 2,
        name: 'Haka Plus Tours',
        description: 'Premium New Zealand Guided Tours',
        url: 'https://hakatours.com/haka-plus-tours',
      },
      {
        '@type': 'SiteNavigationElement',
        position: 3,
        name: 'New Zealand Snow Packages',
        description: 'NZ Skiing & Snowboarding Tours',
        url: 'https://hakatours.com/new-zealand-snow-tours/',
      },
      {
        '@type': 'SiteNavigationElement',
        position: 4,
        name: 'Private Tours',
        description: 'Private Tours',
        url: 'https://hakatours.com/private-tours/',
      },
      {
        '@type': 'SiteNavigationElement',
        position: 5,
        name: 'New Zealand Package Deals',
        description: 'Save BIG on Last Minute and Early Bird Departures',
        url: 'https://hakatours.com/discounts/',
      },
      {
        '@type': 'SiteNavigationElement',
        position: 6,
        name: 'Latest Reviews',
        description: 'What our customers have to say',
        url: 'https://hakatours.com/about-us/reviews/',
      },
    ],
  },
];

class HomePage extends PureComponent {
  /**
   * Render
   */
  render() {
    const { data } = this.props;
    const alertMessage = [
      {
        type: 'success',
        content: '<span>Selected last minute deals available - click here</span>',
      },
    ];

    return (
      <Layout page="home">
        <Helmet
          title="Haka Tours: New Zealand Tour Operator"
          meta={[
            { name: 'description', content: 'Haka Tours is an award-winning New Zealand tour operator. NZ owned & operated, our guides promise a 100% authentic Kiwi experience. Browse our tours online!' },
            { name: 'og:title', content: 'Haka Tours: New Zealand Tour Operator' },
            { name: 'og:description', content: 'Haka Tours is an award-winning New Zealand tour operator. NZ owned & operated, our guides promise a 100% authentic Kiwi experience. Browse our tours online!' },
            { name: 'og:image:secure', content: 'https://static-hakatours.imgix.net/home-bg-compressed.jpg?fit=crop&auto=format' },
            { name: 'og:image', content: 'https://static-hakatours.imgix.net/home-bg-compressed.jpg?fit=crop&auto=format' },
          ]}
          link={[{ rel: 'canonical', href: `${BASE_URL}/` }]}
        >
          <script type="application/ld+json">
            {JSON.stringify(organisationSchema)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(navigationSchema)}
          </script>
        </Helmet>
        <HomeSearchBanner />
        <Link to={`/discounts/`} style={{ textDecoration: 'none' }}>
          <AlertBanner messages={alertMessage} />
        </Link>
        <BestInNZ />

        <Lazyload
          height={200}
          offset={50}
          once
          resize
        >
          <CSSTransition
            key="1"
            transitionName="fade"
            timeout={100}
            transitionAppear
            transitionAppearTimeout={500}
            transitionEnter={false}
            transitionLeave={false}
          >
            <TourCategories />
          </CSSTransition>
        </Lazyload>

        <Lazyload
          offset={100}
          height={200}
          once
          resize
        >
          <CSSTransition
            key="1"
            transitionName="fade"
            timeout={100}
            transitionAppear
            transitionAppearTimeout={500}
            transitionEnter={false}
            transitionLeave={false}
          >
            <Review />
          </CSSTransition>
        </Lazyload>

        <Lazyload throttle={200} height={300}>
          <CSSTransition
            key="1"
            transitionName="fade"
            timeout={100}
            transitionAppear
            transitionAppearTimeout={500}
            transitionEnter={false}
            transitionLeave={false}
          >
            <TourList
              heading={<h2 className="c-heading c-heading--h2">Our most popular tours</h2>}
              list={FORMAT_GRAPH_DATA(data.allTourPages)}
              hideFilters
            />
          </CSSTransition>
        </Lazyload>
        <Lazyload
          offset={100}
          height={200}
          once
          resize
        >
          <CSSTransition
            key="1"
            transitionName="fade"
            timeout={100}
            transitionAppear
            transitionAppearTimeout={500}
            transitionEnter={false}
            transitionLeave={false}
          >
            <Awards />
          </CSSTransition>
        </Lazyload>
        <Lazyload
          height={200}
          offset={100}
          once
          resize
        >
          <CSSTransition
            key="1"
            transitionName="fade"
            timeout={100}
            transitionAppear
            transitionAppearTimeout={500}
            transitionEnter={false}
            transitionLeave={false}
          >
            <ActivityList
              title="POPULAR ACTIVITIES ON TOUR"
              subTitle="You can make a tour more awesome by adding optional activities. See one you love? Click the heart icon to save it to My Favourites."
              list={FORMAT_GRAPH_DATA(data.allActivityPages)}
              showFooter
            />
          </CSSTransition>
        </Lazyload>

        <Lazyload
          height={200}
          offset={100}
          once
          resize
        >
          <CSSTransition
            key="1"
            transitionName="fade"
            timeout={100}
            transitionAppear
            transitionAppearTimeout={500}
            transitionEnter={false}
            transitionLeave={false}
          >
            <InstagramList
              title="WHAT TO EXPECT ON A #HAKATOUR"
              subTitle="Want to be featured here? Simply tag your Insta pics with #hakatours or upload your pictures <a href='https://upload.crowdriff.com/haka-tours-guest-images' target='_blank'>here</a>."
              hash="c6f7ed1fdaef7c3e"
            />
          </CSSTransition>
        </Lazyload>

        {/* <BlogList */}
        {/* title="What we’ve been up to" */}
        {/* list={[1, 2]} */}
        {/* /> */}

        <Lazyload
          height={400}
          offset={100}
          once
          resize
        >
          <CSSTransition
            key="1"
            transitionName="fade"
            timeout={100}
            transitionAppear
            transitionAppearTimeout={500}
            transitionEnter={false}
            transitionLeave={false}
          >
            <ProudSupporters
              title="Proud Supporters of the Tiaki Promise"
            />
          </CSSTransition>
        </Lazyload>

        <Lazyload
          height={400}
          offset={100}
          once
          resize
        >
          <CSSTransition
            key="1"
            transitionName="fade"
            timeout={100}
            transitionAppear
            transitionAppearTimeout={500}
            transitionEnter={false}
            transitionLeave={false}
          >
            <FooterBanner />
          </CSSTransition>
        </Lazyload>
      </Layout>
    );
  }
}

HomePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    allTourPages(
      limit: 6,
      sort: {fields: [order], order: ASC},
      filter: {
        is_popular: { eq: true },
        hidden_category: { eq: false },
        archived: { eq: false }
      }
    ) {
      edges {
        node {
          id
          api_id
          name
          slug
          number_of_days
          starting_price
          background_color
          short_description_markdown
          parent_name
          absolute_slug
          destination_ids
          category_ids
          picture_id
          small_map_id
          sliding_first_picture_id
          sliding_second_picture_id
          sliding_third_picture_id
          sliding_fourth_picture_id
          sliding_fifth_picture_id
          sliding_sixth_picture_id
          sliding_seventh_picture_id
          sliding_eighth_picture_id
          sliding_ninth_picture_id
          sliding_tenth_picture_id
          category_icons {
            id
            name
            tooltip
            svg_text
          }
          parent_category_icon {
            id
            svg_text
          }
        }
      }
    }

    allActivityPages(
      limit: 8,
      filter: {
        is_popular: { eq: true },
        guide_app_only: { ne: true },
      }
    ) {
      edges {
        node {
          id
          api_id
          name
          location
          absolute_slug
          sliding_first_picture_id
          options {
            price
            hidden
            archived
            seasonal_pricings {
              id
              price
              available_from
              available_to
              hidden
            }
          }
        }
      }
    }

    allReviews(
      limit: 3,
      sort: {
        fields: date_published,
        order: DESC
      }
      filter: {
        date_published: {
          ne: null
        }
      }
    ) {
      edges {
        node {
          id
          api_id
          author
          country
          text
          image_url
          date_published
        }
      }
    }
  }
`;

export default HomePage;
